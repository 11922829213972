<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab == 'stockCheckTask'">
        <stock-check-task-panel></stock-check-task-panel>
      </div>
      <div v-else-if="currentTab == 'stockCheckRecord'">
        <stock-check-record-panel></stock-check-record-panel>
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    StockCheckTaskPanel: () => import("./StockCheckTaskPanel/index"),
    StockCheckRecordPanel: () => import("./StockCheckRecordPanel/index"),
  },
  data() {
    return {
      tabList: [
        {
          key: "stockCheckTask",
          tab: this.$t("盘点单"),
        },
        {
          key: "stockCheckRecord",
          tab: this.$t("盘点记录"),
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
